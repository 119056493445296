import React from "react";
import logo from "./../res/logo.svg";

function Heading(props) {
    return (
        <>
            <h1 className="is-center heading">{props.children}</h1>
            <div className="row">
                <div className="col is-center">
                    <img className="logo" src={logo} alt="" />
                </div>
            </div>
        </>
    );
}

export default Heading;
