import React from "react";

function Card(props) {
    return (
        <div className="col-12 col-4-lg col-6-md">
            <div className="card">
                <header className="is-center">
                    <h4>{props.title}</h4>
                </header>
                <div className="row">
                    <div className="col-12 is-center">
                        <img alt="" src={props.image}></img>
                    </div>
                    <div className="col-12 is-center">
                        <p>{props.body}</p>
                    </div>
                </div>
                <footer className="is-center">
                    {props.btn.map((btn) => (
                        <a
                            target="_blank"
                            key={btn.text}
                            href={btn.url}
                            className="button primary"
                            rel="noreferrer"
                        >
                            {btn.text}
                        </a>
                    ))}
                </footer>
            </div>
        </div>
    );
}

export default Card;
