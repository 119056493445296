import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BeerGamePrivacy from "./components/terms/BeerGamePrivacy";
import BeerGameTerms from "./components/terms/BeerGameTerms";
import Home from "./views/Home";
import HtmlView from "./views/HtmlView";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/privacy/beerGame">
                    <HtmlView>
                        <BeerGamePrivacy></BeerGamePrivacy>
                    </HtmlView>
                </Route>
                <Route path="/terms/beerGame">
                    <HtmlView>
                        <BeerGameTerms></BeerGameTerms>
                    </HtmlView>
                </Route>

                <Route path="/">
                    <Home></Home>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
