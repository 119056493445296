import { Link } from "react-router-dom";

function HtmlView(props) {
    return (
        <div className="container" role="document">
            <main role="main">
                {props.children}
                <Link style={{ paddingBottom: "5rem" }} to="/">
                    Nux Blox Home
                </Link>
            </main>
        </div>
    );
}

export default HtmlView;
