import React from "react";
import Card from "./../components/Card";
import Heading from "./../components/Heading";
import beerBanner from "./../res/beerBanner.png";

function Home() {
    return (
        <div className="container" role="document">
            <main role="main">
                <Heading>Nux Blox</Heading>
                <div className="row is-center">
                    <Card
                        title="Beer Game"
                        image={beerBanner}
                        body="Crack open a cold one 🍺"
                        btn={[
                            {
                                text: "Google Play",
                                url: "https://play.google.com/store/apps/details?id=com.nuxblox.beergame",
                            },
                        ]}
                    ></Card>
                </div>
            </main>
        </div>
    );
}

export default Home;
